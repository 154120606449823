import React from 'react';
import ContentContainer from '../components/common/ContentContainer';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

const Contact = () => {
  return (
    <Layout>
      <SEO title="Contact" />
      <ContentContainer>
        <iframe
          src={'https://app.hellobonsai.com/f/7c10e391efea5c0?embed=true'}
          frameBorder="0"
          width="100%"
          height="1800px"
        ></iframe>
      </ContentContainer>
    </Layout>
  );
};

export default Contact;
